import { useEffect, useState } from 'react';
import { Map, Marker } from "react-map-gl";
import useApi from "../../hooks/useApi";
import { useQuery } from "react-query";
import '../../assets/styles/marker.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Title } from 'components/Title';

export default function HomePage() {
    const [filter, setFilter] = useState("all"); // الحالة المراد تصفيتها
    const [selectedReport, setSelectedReport] = useState(null); // لتحديد البلاغ الذي تم النقر عليه
    const api = useApi();
    const { isLoading, isError, data } = useQuery(
        ['report', 'reports/index'],
        () => api.get('reports/index')
    );

    const reat_token = process.env.REACT_APP_MAPBOX_TOKEN;
    // console.log(reat_token)
    // تحديد لون العلامة حسب الحالة
    const getMarkerColor = (status) => {
        switch (status) {
            case 'pending': return '#FFA500'; // برتقالي
            case 'in_progress': return '#0000FF'; // أزرق
            case 'finished': return '#008000'; // أخضر

        }
    };

    // تصفية البيانات حسب الحالة المحددة
    const filteredData = data?.data.records.filter((d) =>
        filter === "all" || d.status === filter
    );

    useEffect(() => {
        console.log(data);
    }, [isLoading]);

    return (
        <main id="HomePage" className="main-page">
            <section className="page-section q-pa-md">
                <div className="w-100">
                <div className="col " >
                    <Title title={'لوحة التحكم '}   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                    </div>
                    <div className="row">
                        {/* قائمة الألوان */}
                        {/* <div className="col-span-5 mb-3">
                            <div className="legend d-flex align-items-center gap-4" style={{ display: 'flex' }}>
                                <div className="d-flex align-items-center gap-2">
                                    <div
                                        className='p-button'
                                        style={{
                                            border: 'none',
                                            backgroundColor: '#FFA500',

                                        }}
                                    >
                                        <span>بلاغات جديدة</span>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center gap-2">

                                    <div
                                        className='p-button'
                                        style={{
                                            border: 'none',
                                            backgroundColor: '#0000FF',

                                        }}
                                    >
                                        <span>قيد الصيانة</span>
                                    </div>


                                </div>
                                <div className="d-flex align-items-center gap-2">

                                    <div
                                        className='p-button'
                                        style={{
                                            border: 'none',
                                            backgroundColor: '#008000',

                                        }}
                                    >
                                        <span>منتهي</span>
                                    </div>

                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-3" >
                            {/* أزرار التصفية */}
                            <div className="d-flex d-md-block ">
                                <Button
                                c
                                    style={{ display: 'block',width:"90%",padding:15 }}
                                    className={`m-2 d-inline-sm ${filter === "all" ? "p-button-primary" : "p-button-outlined"}`}
                                    onClick={() => setFilter("all")}
                                >
                                    عرض الكل
                                </Button>
                                <Button
                                    style={{ display: 'block',width:"90%" ,padding:15}}
                                    className={`m-2 ${filter === "pending" ? "p-button-warning" : "p-button-outline-warning"}`}
                                    onClick={() => setFilter("pending")}
                                >
                                    بلاغات جديدة
                                </Button>
                                <Button
                                    style={{ display: 'block',width:"90%" ,padding:15}}
                                    className={`m-2 ${filter === "in_progress" ? "p-button-info" : "p-button-outline-info"}`}
                                    onClick={() => setFilter("in_progress")}
                                >
                                    قيد الصيانة
                                </Button>
                                <Button
                                    style={{ display: 'block',width:"90%",padding:15 }}
                                    className={`m-2 ${filter === "finished" ? "p-button-success" : "p-button-outline-success"}`}
                                    onClick={() => setFilter("finished")}
                                >
                                    منتهي
                                </Button>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className="card overflow-hidden p-0" style={{ height: 500, position: "relative" }}>
                                <Map
                                    mapboxAccessToken={reat_token}
                                    initialViewState={{
                                        longitude: 14.4283,
                                        latitude: 27.0377,
                                        zoom: 13,
                                    }}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    mapStyle="mapbox://styles/mapbox/streets-v9"
                                >
                                    {/* عرض العلامات */}
                                    {filteredData?.map((d) => (
                                        <Marker
                                            key={d.reportid}
                                            longitude={d.lng}
                                            latitude={d.lat}
                                            anchor="top"
                                        >
                                            <div
                                                className="outer-circle"
                                                style={{
                                                    backgroundColor: getMarkerColor(d.status),
                                                    cursor: "pointer",
                                                    borderRadius:"50%"
                                                }}
                                                onClick={() => setSelectedReport(d)} // عرض تفاصيل البلاغ عند النقر
                                            >
                                                
                                            </div>
                                        </Marker>
                                    ))}
                                </Map>

                                {/* النافذة الجانبية لعرض التفاصيل */}
                                {selectedReport && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            width: "30%",
                                            height: "100%",
                                            backgroundColor: "#fff",
                                            borderLeft: "1px solid #ddd",
                                            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                            padding: "15px",
                                            overflowY: "auto",
                                        }}
                                    >
                                        <Panel header="تفاصيل البلاغ" className="mb-3">
                                            <p><strong>الوصف:</strong> {selectedReport.description || 'لا يوجد وصف'}
                                            </p>
                                            <p><strong>الحالة:</strong> {selectedReport.status}</p>
                                            <p><strong>التاريخ:</strong> {selectedReport.reportdate || 'غير متوفر'}
                                            </p>
                                            <p>
                                                <strong>الموقع:</strong> {`Lat: ${selectedReport.lat}, Lng: ${selectedReport.lng}`}
                                            </p>

                                            <Button
                                                label="تفاصيل أكثر"
                                                className="p-button-primary mt-2"
                                                onClick={() => window.location.href = `/reports/view/${selectedReport.reportid}`}
                                            />
                                            <Button
                                                label="إغلاق"
                                                className="p-button-secondary mt-2"
                                                onClick={() => setSelectedReport(null)} // إغلاق النافذة
                                            />
                                        </Panel>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

