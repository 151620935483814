import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { $t } from 'hooks/i18n';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import { Map, Marker } from "react-map-gl"; // خريطة Mapbox

import useAddPage from 'hooks/useAddPage';

const ServicerequestsAddPage = (props) => {
    const app = useApp();
    const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

     // form validation rules
     const validationSchema = yup.object().shape({
        full_name: yup.string().required().label($t('fullName')),
        phone_number: yup.string().required().label($t('phoneNumber')),
        service_id: yup.string().required().label($t('serviceId')),
        lat: yup.number().required().label($t('latitude')),
        lan: yup.number().required().label($t('longitude')),
        count: yup.number().required().label($t('visitCount')),
 
    });

    // form default values
    const formDefaultValues = {
        full_name: '',
        phone_number: '',
        service_id: '',
        lat: 27.0377, // Default latitude
        lan: 14.4283, // Default longitude
        count: 0,
       
    };

  
    // page hook where logics resides
    const pageController = useAddPage({ props, formDefaultValues, afterSubmit });

    // destructure and grab what the page needs
    const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;

    // event raised after form submit
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        resetForm();
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        } else if (props.redirect) {
            app.navigate(`/servicerequests`);
        }
    }

    // page loading form data from api
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
            </div>
        );
    }

    // page has loaded any required data and ready to render
    if (pageReady) {
        return (
            <main id="ServicerequestsAddPage" className="main-page">
                {props.showHeader && (
                    <section className="page-section mb-3">
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage && (
                                    <div className="col-fixed">
                                        <Button onClick={() => app.navigate(-1)} label={$t('')} className="p-button p-button-text" icon="pi pi-arrow-left" />
                                    </div>
                                )}
                                <div className="col">
                                    <Title title={$t('addNewServiceRequest')} titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <section className="page-section">
                    <div className="container">
                        <div className="grid">
                            <div className="md:col-9 sm:col-12 comp-grid">
                                <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) => submitForm(values)}>
                                    {(formik) => (
                                        <>
                                            <Form className={`${!props.isSubPage ? 'card' : ''}`}>
                                                <div className="grid">
                                               
                                                    {/* Full Name Field */}
                                                    <div className="col-12">
                                                        <div className="formgrid grid">
                                                            <div className="col-12 md:col-3">{$t('fullName')} *</div>
                                                            <div className="col-12 md:col-9">
                                                                <InputText
                                                                    name="full_name"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.full_name}
                                                                    placeholder={$t('enterFullName')}
                                                                    className={inputClassName(formik?.errors?.full_name)}
                                                                />
                                                                <ErrorMessage name="full_name" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Phone Number Field */}
                                                    <div className="col-12">
                                                        <div className="formgrid grid">
                                                            <div className="col-12 md:col-3">{$t('phoneNumber')} *</div>
                                                            <div className="col-12 md:col-9">
                                                                <InputText
                                                                    name="phone_number"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.phone_number}
                                                                    placeholder={$t('enterPhoneNumber')}
                                                                    className={inputClassName(formik?.errors?.phone_number)}
                                                                />
                                                                <ErrorMessage name="phone_number" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Service ID Field */}
                                                    <div className="col-12">
                                                        <div className="formgrid grid">
                                                            <div className="col-12 md:col-3">{$t('serviceId')} *</div>
                                                            <div className="col-12 md:col-9">
                                                                <DataSource apiPath="components_data/service_id_option_list">
                                                                    {({ response }) => (
                                                                        <>
                                                                            <Dropdown
                                                                                name="service_id"
                                                                                optionLabel="label"
                                                                                optionValue="value"
                                                                                value={formik.values.service_id}
                                                                                onChange={formik.handleChange}
                                                                                options={response}
                                                                                placeholder={$t('selectAValue')}
                                                                                className={inputClassName(formik?.errors?.service_id)}
                                                                            />
                                                                            <ErrorMessage name="service_id" component="span" className="p-error" />
                                                                        </>
                                                                    )}
                                                                </DataSource>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Map for Latitude and Longitude */}
                                                    <div className="col-12">
                                                        <div className="card overflow-hidden p-0" style={{ height: 500, position: 'relative' }}>
                                                            <Map
                                                                mapboxAccessToken={mapboxToken}
                                                                initialViewState={{
                                                                    longitude: formik.values.lan,
                                                                    latitude: formik.values.lat,
                                                                    zoom: 13,
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                                onClick={(e) => {
                                                                    const { lng, lat } = e.lngLat;
                                                                    formik.setFieldValue('lat', `${lat}`);
                                                                    formik.setFieldValue('lan', `${lng}`);
                                                                }}
                                                                mapStyle="mapbox://styles/mapbox/streets-v9"
                                                            >
                                                                <Marker latitude={formik.values.lat} longitude={formik.values.lan} />
                                                            </Map>
                                                        </div>
                                                    </div>

                                                    {/* Visit Count Field */}
                                                    <div className="col-12">
                                                        <div className="formgrid grid">
                                                            <div className="col-12 md:col-3">عدد الزيارات *</div>
                                                            <div className="col-12 md:col-9">
                                                                <InputText
                                                                    name="count"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.count}
                                                                    type="number"
                                                                    placeholder={$t('enterCount')}
                                                                    min={0}
                                                                    step="any"
                                                                    className={inputClassName(formik?.errors?.count)}
                                                                />
                                                                <ErrorMessage name="count" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    {/* Status Field */}
                                                    
                                                </div>
                                                {props.showFooter && (
                                                    <div className="text-center my-3">
                                                        <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label={$t('submit')} icon="pi pi-send" loading={saving} />
                                                    </div>
                                                )}
                                            </Form>
                                        </>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
};

// page props and default values
ServicerequestsAddPage.defaultProps = {
    primaryKey: 'id',
    pageName: 'servicerequests',
    apiPath: 'servicerequests/add',
    routeName: 'servicerequestsadd',
    submitButtonLabel: $t('submit'),
    formValidationError: $t('formIsInvalid'),
    formValidationMsg: $t('pleaseCompleteTheForm'),
    msgTitle: $t('createRecord'),
    msgAfterSave: $t('recordAddedSuccessfully'),
    msgBeforeSave: $t(''),
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false,
};

export default ServicerequestsAddPage;
