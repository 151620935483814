import { useEffect, useState } from 'react';
import { Map, Marker } from 'react-map-gl';
import { $t } from 'hooks/i18n';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import ReportsEditPage from 'pages/reports/Edit';
import useApp from 'hooks/useApp';
import useViewPage from 'hooks/useViewPage';
import 'mapbox-gl/dist/mapbox-gl.css';

const ReportsViewPage = (props) => {
    const app = useApp();

    const url = process.env.REACT_APP_API_URL;
    const pageController = useViewPage(props);
    const { item, pageReady, loading, apiRequestError, deleteItem } = pageController;

    function ActionButton(data) {
        const items = [
            {
                label: $t('edit'),
                command: () => {
                    app.openPageDialog(<ReportsEditPage isSubPage apiPath={`/reports/edit/${data.reportid}`} />, { closeBtn: true });
                },
                icon: 'pi pi-pencil',
            },
            {
                label: $t('delete'),
                command: () => {
                    deleteItem(data.reportid);
                },
                icon: 'pi pi-trash',
            },
        ];
        return <Menubar className="p-0 " model={items} />;
    }

    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
            </div>
        );
    }
    if (apiRequestError) {
        return <PageRequestError error={apiRequestError} />;
    }
    if (pageReady) {
        return (
            <div>
                <main id="ReportsViewPage" className="main-page">
                    {props.showHeader && (
                        <section className="page-section mb-3">
                            <div className="container">
                                <div className="grid justify-content-between align-items-center">
                                    {!props.isSubPage && (
                                        <div className="col-fixed ">
                                            <Button
                                                onClick={() => app.navigate(-1)}
                                                label={$t('')}
                                                className="p-button p-button-text "
                                                icon="pi pi-arrow-left"
                                            />
                                        </div>
                                    )}
                                    <div className="col ">
                                        <Title
                                            title={$t('reportDetails')}
                                            titleClass="text-2xl text-primary font-bold"
                                            subTitleClass="text-500"
                                            separator={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                    <section className="page-section ">
                        <div className="container">
                            <div className="grid">
                                {/* الخريطة */}
                                <div className="col-12 md:col-6">
                                    <div style={{ height: '400px', width: '100%' }}>
                                        <Map
                                            mapboxAccessToken="pk.eyJ1IjoibXVldGF6YWxzYWhidSIsImEiOiJjbTJpc3dvZXMwanZlMm1xeHJjbnRheDloIn0.8ycuLuUvKgimYLLVjT6qFw"
                                            initialViewState={{
                                                longitude: item.lng,
                                                latitude: item.lat,
                                                zoom: 14,
                                            }}
                                            style={{ width: '100%', height: '100%' }}
                                            mapStyle="mapbox://styles/mapbox/streets-v9"
                                        >
                                            <Marker longitude={item.lng} latitude={item.lat} anchor="bottom" >
                                                <div className="marker-icon" style={{
                                                    backgroundColor: "red",
                                                    width: 50,
                                                    height: 50,
                                                    borderRadius:'50%',
                                                }} />
                                            </Marker>
                                        </Map>
                                    </div>
                                </div>

                                {/* التفاصيل */}
                                <div className="col-12 md:col-6">
                                    <div className="mb-3">
                                        <h3 className="text-lg font-bold mb-3">{$t('reportDetails')}</h3>
                                        <div className="grid">
                                            <div className="col-6">
                                                <div className="text-400 font-medium mb-1">{$t('reportid')}</div>
                                                <div className="font-bold">{item.reportid}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-400 font-medium mb-1">{$t('description')}</div>
                                                <div className="font-bold">{item.description}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-400 font-medium mb-1">{$t('reportdate')}</div>
                                                <div className="font-bold">{item.reportdate}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-400 font-medium mb-1">{$t('status')}</div>
                                                <div className="font-bold">{item.status}</div>
                                            </div>
                                        </div>

                                        {/* زر إرسال فرق الصيانة */}
                                        <div className="mt-3">
                                            <Button
                                                label={'ارسال الصيانة للباغ'}
                                                icon="pi pi-send"
                                                className="p-button-success"
                                                onClick={() => app.navigate(`/processreports/add`)}
                                            />
                                        </div>
                                    </div>

                                    {/* عرض المرفقات */}
                                    <div className="mt-5">
                                        <h3 className="text-lg font-bold mb-3">{$t('attachments')}</h3>
                                        <div className="grid">
                                            {item.attachments?.length > 0 ? (
                                                item.attachments.map((attachment, index) => (
                                                    <div key={index} className="col-12 md:col-4 mb-3">
                                                        <img
                                                            src={url + attachment.file_path}
                                                            alt="Attachment"
                                                            width={120}
                                                            height={120}
                                                            className="w-full h-auto border border-200 rounded"
                                                        />
                                                    </div>
                                                ))
                                            ) : (
                                                <p className="text-400">{$t('noAttachments')}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        );
    }
};

ReportsViewPage.defaultProps = {
    id: null,
    primaryKey: 'reportid',
    pageName: 'reports',
    apiPath: 'reports/view',
    routeName: 'reportsview',
    msgBeforeDelete: $t('promptDeleteRecord'),
    msgTitle: $t('deleteRecord'),
    msgAfterDelete: $t('recordDeletedSuccessfully'),
    showHeader: true,
    showFooter: true,
    exportButton: true,
    isSubPage: false,
};
export default ReportsViewPage;
