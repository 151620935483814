import { $t } from 'hooks/i18n';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import ServicetypesViewPage from 'pages/servicetypes/View';
import useApp from 'hooks/useApp';
import UsersViewPage from 'pages/users/View';

import useListPage from 'hooks/useListPage';
const ServicerequestsListPage = (props) => {
		const app = useApp();
	const filterSchema = {
		search: {
			tagTitle: $t('search'),
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	function StatusTemplate(data) {
		const getStatusColor = (status) => {
			switch (status) {
				case 'pending':
					return 'badge-warning'; // لون أصفر
				case 'in_progress':
					return 'badge-info'; // لون أزرق
				case 'finished':
					return 'badge-success'; // لون أخضر
				case 'cancelled':
					return 'badge-danger'; // لون أحمر
				default:
					return 'badge-secondary'; // لون رمادي
			}
		};

		return (
			<span className={`badge ${getStatusColor(data.status)}`}>
            {$t(data.status)}
        </span>
		);
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;
	function ActionButton(data){
		const items = [
		{
			label: $t('view'),
			command: (event) => { app.navigate(`/servicerequests/view/${data.id}`) },
			icon: "pi pi-eye"
		},
		{
			label: $t('edit'),
			command: (event) => { app.navigate(`/servicerequests/edit/${data.id}`) },
			icon: "pi pi-pencil"
		},
		{
			label: $t('delete'),
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash"
		}
	]
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function UserIdTemplate(data){
		if(data){
			return (
				<>{data.name + ' ' + data.phone}</>
			);
		}
	}
	function ServiceIdTemplate(data){

		if(data){
			console.log(data.service_type_name)
			return (
				<>{data.service_type_name && <p>{data.service_type_name}</p> }</>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">{$t('loading')}</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					{$t('noRecordFound')}
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title={$t('deleteSelected')}/>
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">{$t('records')} <b>{ recordsPosition } {$t('of')} { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main id="ServicerequestsListPage" className="main-page">
    { (props.showHeader) &&
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                <div className="col " >
                    <Title title={$t('serviceRequests')}   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
                <div className="col-fixed " >
                    <Link to={`/servicerequests/add`}>
                        <Button label={$t('addNewServiceRequest')} icon="pi pi-plus" type="button" className="p-button w-full bg-primary "  />
                        </Link>
                    </div>
                    <div className="col-12 md:col-3 " >
                        <span className="p-input-icon-left w-full">
                        <i className="pi pi-search" />
                        <InputText placeholder={$t('search')} className="w-full" value={filters.search.value}  onChange={(e) => setFilterValue('search', e.target.value)} />
                        </span>
                    </div>
                </div>
            </div>
        </section>
        }
        <section className="page-section " >
            <div className="container-fluid">
                <div className="grid ">
                    <div className="col comp-grid" >
                        <FilterTags filterController={filterController} />
                        <div >
                            <PageBreadcrumbs />
                            <div className="page-records">
                                <DataTable
                                    lazy={true}
                                    loading={loading}
                                    selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                                    value={records}
                                    dataKey="id"
                                    sortField={sortBy}
                                    sortOrder={sortOrder}
                                    onSort={onSort}
                                    className=" p-datatable-sm"
                                    align="center"
                                    stripedRows={true}
                                    showGridlines={false}
                                    rowHover={true}
                                    responsiveLayout="stack"
                                    emptyMessage={<EmptyRecordMessage />}
                                    >
                                    {/*PageComponentStart*/}
                                    <Column selectionMode="multiple" headerStyle={{width: '2rem'}}></Column>
                                    <Column  field="user_id" header={$t('userId')} body={UserIdTemplate}  ></Column>
                                    <Column  field="service_id" header={$t('serviceId')} body={ServiceIdTemplate}  ></Column>
                                    {/* <Column  field="lat" header={$t('lat')}   ></Column>
                                    <Column  field="lan" header={$t('lan')}   ></Column> */}
                                    <Column  field="count" header={$t('count')}   ></Column>
                                    <Column  field="cost" header={$t('cost')}   ></Column>
                                    <Column  field="status" header={$t('status')}  body={StatusTemplate} ></Column>
                                    <Column  field="created_at" header={$t('createdAt')}   ></Column>
                                    <Column  field="updated_at" header={$t('updatedAt')}   ></Column>
                                    <Column headerStyle={{width: '2rem'}} headerClass="text-center" body={ActionButton}></Column>
                                    {/*PageComponentEnd*/}
                                </DataTable>
                            </div>
                            <PageFooter />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
	);
}
ServicerequestsListPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'servicerequests',
	apiPath: 'servicerequests/index',
	routeName: 'servicerequestslist',
	msgBeforeDelete: $t('promptDeleteRecord'),
	msgTitle: $t('deleteRecord'),
	msgAfterDelete: $t('recordDeletedSuccessfully'),
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default ServicerequestsListPage;
