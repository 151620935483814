import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { Toast } from 'primereact/toast';

const ProcessServiceCalendar = () => {
	const queryClient = useQueryClient();
	const toast = React.useRef(null);

	// State لإدارة التاريخ المختار
	const [selectedDate, setSelectedDate] = useState(null);
	const [showDialog, setShowDialog] = useState(false);
	const [formData, setFormData] = useState({
		service_request_id: '',
		team_id: '',
		details: '',
	});

	// جلب طلبات الخدمة
	const { data: serviceRequests = [], isLoading: loadingServiceRequests } = useQuery(
		'serviceRequests',
		async () => {
			const response = await axios.get('/servicerequests/index/status/pending');
			return response.data.records.map((req) => ({
				label: `${req.service_type_name} رقم الطلب #${req.id}`,
				value: req.id,
			}));
		}
	);

	// جلب الفرق
	const { data: teams = [], isLoading: loadingTeams } = useQuery('teams', async () => {
		const response = await axios.get('/teams');
		return response.data.records.map((team) => ({
			label: team.teamname,
			value: team.teamid,
		}));
	});

	// جلب الأحداث (الخدمات المسندة)
	const { data: events = [], isLoading: loadingEvents } = useQuery(
		'processServices',
		async () => {
			const response = await axios.get('/processservice');
			return response.data.records.map((item) => ({
				id: item.id,
				title: item.service_type_name + " - " + item.team_name + ' - رقم الطلب '+item.service_request_id,
				start: item.assigned_date,
				backgroundColor: item.status === 'finished' ? 'gray' : 'green',
			}));
		}
	);

	// إرسال البيانات باستخدام Mutation
	const mutation = useMutation(
		async (newProcessService) => {
			const response = await axios.post('/processservice/add', newProcessService);
			return response.data;
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('processServices'); // تحديث البيانات
				toast.current.show({ severity: 'success', summary: 'Success', detail: 'Process Service Added Successfully!' });
				setShowDialog(false);
				setFormData({ service_request_id: '', team_id: '', details: '' });
			},
			onError: () => {
				toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to add process service.' });
			},
		}
	);

	// عند الضغط على يوم
	const handleDateClick = (info) => {
		setSelectedDate(info.dateStr);
		setShowDialog(true);
	};

	// إرسال البيانات عند الحفظ
	const handleSubmit = () => {
		if (!formData.service_request_id || !formData.team_id) {
			toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please fill all required fields.' });
			return;
		}
		mutation.mutate({ ...formData, assigned_date: selectedDate });
	};

	// عرض التحميل
	if (loadingServiceRequests || loadingTeams || loadingEvents) {
		return (
			<div className="flex justify-content-center align-items-center">
				<h3>Loading...</h3>
			</div>
		);
	}



	return (
		<div className="container">
			<Toast ref={toast} />
			<h1 className="text-center">Process Services Calendar</h1>

			{/* التقويم */}
			<FullCalendar
				plugins={[dayGridPlugin, interactionPlugin]}
				initialView="dayGridMonth"
				events={events}
				dateClick={handleDateClick}
				locale="en"
			/>

			{/* نافذة الحوار */}
			<Dialog
				header={`Assign Service Request - ${selectedDate}`}
				visible={showDialog}
				style={{ width: '50vw' }}
				onHide={() => setShowDialog(false)}
			>
				<div className="p-fluid">
					<div className="field">
						<label htmlFor="service_request_id">Service Request</label>
						<Dropdown
							id="service_request_id"
							value={formData.service_request_id}
							options={serviceRequests}
							onChange={(e) => setFormData({ ...formData, service_request_id: e.value })}
							placeholder="اختيار الطلب"
						/>
					</div>
					<div className="field">
						<label htmlFor="team_id">Team</label>
						<Dropdown
							id="team_id"
							value={formData.team_id}
							options={teams}
							onChange={(e) => setFormData({ ...formData, team_id: e.value })}
							placeholder="اختيار الفريق"
						/>
					</div>
					<div className="field">
						<label htmlFor="details">Details</label>
						<InputTextarea
							id="details"
							value={formData.details}
							onChange={(e) => setFormData({ ...formData, details: e.target.value })}
							rows={4}
							placeholder="تفاصيل اضافية (optional)"
						/>
					</div>
				</div>
				<div className="text-right mt-3">
					<Button
						label="Assign"
						icon="pi pi-check"
						className="p-button-success"
						onClick={handleSubmit}
						loading={mutation.isLoading}
					/>
				</div>
			</Dialog>
		</div>
	);
};

export default ProcessServiceCalendar;
