import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { $t } from 'hooks/i18n';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import { Map, Marker } from "react-map-gl";

import useEditPage from 'hooks/useEditPage';

const ReportsEditPage = (props) => {
    const app = useApp();
    const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

    // form validation schema
    const validationSchema = yup.object().shape({
        description: yup.string().required().label($t('description')),
        tasktype: yup.string().nullable().label($t('tasktype')),
        lat: yup.number().required().label($t('lat')),
        lng: yup.number().required().label($t('lng')),
    });

    // form default values
    const formDefaultValues = {
        description: '',
        tasktype: '',
        lat: 27.0377, // Default latitude
        lng: 14.4283, // Default longitude
    };

    // where page logic resides
    const pageController = useEditPage({ props, formDefaultValues, afterSubmit });

    // destructure and grab what we need
    const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController;

    // Event raised on form submit success
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        } else if (props.redirect) {
            app.navigate(`/reports`);
        }
    }

    // loading form data from API
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
            </div>
        );
    }

    // display error page
    if (apiRequestError) {
        return <PageRequestError error={apiRequestError} />;
    }

    // page is ready when form data loaded successfully
    if (pageReady) {
        return (
            <main id="ReportsEditPage" className="main-page">
                {props.showHeader && (
                    <section className="page-section mb-3">
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage && (
                                    <div className="col-fixed">
                                        <Button
                                            onClick={() => app.navigate(-1)}
                                            label={$t('')}
                                            className="p-button p-button-text"
                                            icon="pi pi-arrow-left"
                                        />
                                    </div>
                                )}
                                <div className="col">
                                    <Title
                                        title={$t('editReport')}
                                        titleClass="text-2xl text-primary font-bold"
                                        subTitleClass="text-500"
                                        separator={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <section className="page-section">
                    <div className="container">
                        <div className="grid">
                            <div className="md:col-9 sm:col-12 comp-grid">
                                <Formik
                                    initialValues={formData}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, actions) => submitForm(values)}
                                >
                                    {(formik) => (
                                        <Form className={`${!props.isSubPage ? 'card' : ''}`}>
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div className="formgrid grid">
                                                        <div className="col-12 md:col-3">{$t('description')} *</div>
                                                        <div className="col-12 md:col-9">
                                                            <InputTextarea
                                                                name="description"
                                                                className={inputClassName(formik?.errors?.description)}
                                                                value={formik.values.description}
                                                                placeholder={$t('enterDescription')}
                                                                onChange={formik.handleChange}
                                                            ></InputTextarea>
                                                            <ErrorMessage
                                                                name="description"
                                                                component="span"
                                                                className="p-error"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="formgrid grid">
                                                        <div className="col-12 md:col-3">{$t('tasktype')}</div>
                                                        <div className="col-12 md:col-9">
                                                            <Dropdown
                                                                name="tasktype"
                                                                optionLabel="label"
                                                                optionValue="value"
                                                                value={formik.values.tasktype}
                                                                onChange={formik.handleChange}
                                                                options={app.menus.tasktype}
                                                                label={$t('tasktype')}
                                                                placeholder={$t('selectAValue')}
                                                                className={inputClassName(formik?.errors?.tasktype)}
                                                            />
                                                            <ErrorMessage
                                                                name="tasktype"
                                                                component="span"
                                                                className="p-error"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card overflow-hidden p-0" style={{ height: 500, position: 'relative' }}>
                                                        <Map
                                                            mapboxAccessToken={mapboxToken}
                                                            initialViewState={{
                                                                longitude: formik.values.lng,
                                                                latitude: formik.values.lat,
                                                                zoom: 13,
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                            onClick={(e) => {
                                                                const { lng, lat } = e.lngLat;
                                                                formik.setFieldValue('lat', `${lat}`);
                                                                    formik.setFieldValue('lng', `${lng}`);
                                                            }}
                                                            mapStyle="mapbox://styles/mapbox/streets-v9"
                                                        >
                                                            <Marker
                                                                latitude={formik.values.lat}
                                                                longitude={formik.values.lng}
                                                            />
                                                        </Map>
                                                    </div>
                                                </div>
                                            </div>
                                            {props.showFooter && (
                                                <div className="text-center my-3">
                                                    <Button
                                                        onClick={(e) => handleSubmit(e, formik)}
                                                        type="submit"
                                                        label={$t('update')}
                                                        icon="pi pi-send"
                                                        loading={saving}
                                                    />
                                                </div>
                                            )}
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
};

ReportsEditPage.defaultProps = {
    primaryKey: 'reportid',
    pageName: 'reports',
    apiPath: 'reports/edit',
    routeName: 'reportsedit',
    submitButtonLabel: $t('update'),
    formValidationError: $t('formIsInvalid'),
    formValidationMsg: $t('pleaseCompleteTheForm'),
    msgTitle: $t('updateRecord'),
    msgAfterSave: $t('recordUpdatedSuccessfully'),
    msgBeforeSave: $t(''),
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false,
};

export default ReportsEditPage;
