import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { $t } from 'hooks/i18n';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const ProcessreportsAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		report_id: yup.string().required().label($t('reportId')),
		status: yup.string().required().label($t('status')),
		details: yup.string().nullable().label($t('details')),
		team_id: yup.string().nullable().label($t('teamId')),
		assigned_date: yup.string().nullable().label($t('assignedDate'))
	});
	
	//form default values
	const formDefaultValues = {
		report_id: '', 
		status: '', 
		details: '', 
		team_id: '', 
		assigned_date: "NULL", 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/processreports`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="ProcessreportsAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label={$t('')}  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title={$t('addNewProcessReport')}   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('reportId')} *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/reportid_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="report_id"     optionLabel="label" optionValue="value" value={formik.values.report_id} onChange={formik.handleChange} options={response} label={$t('reportId')}  placeholder={$t('selectAValue')}  className={inputClassName(formik?.errors?.report_id)}   />
                                                    <ErrorMessage name="report_id" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('status')} *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="status"     optionLabel="label" optionValue="value" value={formik.values.status} onChange={formik.handleChange} options={app.menus.status} label={$t('status')}  placeholder={$t('selectAValue')}  className={inputClassName(formik?.errors?.status)}   />
                                                <ErrorMessage name="status" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('details')} 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="details"  className={inputClassName(formik?.errors?.details)}   value={formik.values.details} placeholder={$t('enterDetails')} onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="details" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('teamId')} 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/team_id_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="team_id"     optionLabel="label" optionValue="value" value={formik.values.team_id} onChange={formik.handleChange} options={response} label={$t('teamId')}  placeholder={$t('selectAValue')}  className={inputClassName(formik?.errors?.team_id)}   />
                                                    <ErrorMessage name="team_id" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('assignedDate')} 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="assigned_date" showButtonBar className={inputClassName(formik?.errors?.assigned_date)} dateFormat="yy-mm-dd" value={formik.values.assigned_date} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="assigned_date" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label={$t('submit')} icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
ProcessreportsAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'processreports',
	apiPath: 'processreports/add',
	routeName: 'processreportsadd',
	submitButtonLabel: $t('submit'),
	formValidationError: $t('formIsInvalid'),
	formValidationMsg: $t('pleaseCompleteTheForm'),
	msgTitle: $t('createRecord'),
	msgAfterSave: $t('recordAddedSuccessfully'),
	msgBeforeSave: $t(''),
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ProcessreportsAddPage;
