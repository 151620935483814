/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/

import { $t } from 'hooks/i18n';

export default function useMenus() {
    
    
    return {
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": $t('home'),
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
  },
  // {
  //   "to": "/attachments",
  //   "label": $t('attachments'),
  //   "icon": "pi pi-th-large",
  //   "iconcolor": "",
  //   "target": "",
  // },
  // {
  //   "to": "/feedback",
  //   "label": $t('feedback'),
  //   "icon": "pi pi-comments",
  //   "iconcolor": "",
  //   "target": "",
  // },
  {
    "to": "/reports",
    "label": $t('reports'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/servicerequests",
    "label": $t('serviceRequests'),
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/processreports",
    "label": 'مهام فرق البلاغات',
    "icon": "pi pi-chart-line",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/processservice",
    "label": 'مهام فرق الخدمات',
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/servicetypes",
    "label": $t('serviceTypes'),
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/teammembers",
    "label": $t('teammembers'),
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/teams",
    "label": $t('teams'),
    "icon": "pi pi-id-card",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/users",
    "label": $t('users'),
    "icon": "pi pi-check-circle",
    "iconcolor": "",
    "target": "",
  },
  // {
  //   "to": "/deployments",
  //   "label": $t('deployments'),
  //   "icon": "pi pi-th-large",
  //   "iconcolor": "",
  //   "target": "",
  // },
  // {
  //   "to": "/issuetrack",
  //   "label": $t('issuetrack'),
  //   "icon": "pi pi-exclamation-circle",
  //   "iconcolor": "",
  //   "target": "",
  // },
 
],
	status: [    
{value: "in_progress", label: $t('inProgress')},
	{value: "finished", label: $t('finished')}
    ],
	tasktype: [    
{value: "Complaint", label: $t('complaint')},
	{value: "ServiceRequest", label: $t('servicerequest')},
	{value: "IncidentReport", label: $t('incidentreport')}
    ],
	status2: [    
{value: "pending", label: $t('pending')},
	{value: "cancelled", label: $t('cancelled')},
	{value: "done", label: $t('done')}
    ],
	usertype: [    
{value: "Citizen", label: $t('citizen')},
	{value: "Supervisor", label: $t('supervisor')},
	{value: "TeamMember", label: $t('teammember')}
    ],
	deploymentsDeploymentsMenu: [
  {
    "label": $t('messages1'),
    "icon": "pi pi-envelope",
  },
  {
    "label": $t('messages2'),
    "icon": "pi pi-envelope",
  },
  {
    "label": $t('messages3'),
    "icon": "pi pi-envelope",
  }
],
        exportFormats: {
            print: {
                label: 'Print',
                icon: 'pi pi-print',
                type: 'print',
                ext: '',
            },
            pdf: {
                label: 'Pdf',
                icon: 'pi pi-file-pdf',
                type: 'pdf',
                ext: 'pdf',
            },
            excel: {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                type: 'excel',
                ext: 'xlsx',
            },
            csv: {
                label: 'Csv',
                icon: 'pi pi-table',
                type: 'csv',
                ext: 'csv',
            },
        },
        locales: {
  "en-US": "English",
  "ar": "Arabic"
}
    }
}