import { useState } from 'react';
import { $t } from 'hooks/i18n';
import { Chart } from 'primereact/chart';
import { DataSource } from 'components/DataSource';
import { ListBox } from 'primereact/listbox';
import { RadioButton } from 'primereact/radiobutton';
import { Title } from 'components/Title';

export default function IssuetrackPage() {
	
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="IssuetrackPage" className="main-page">
<section className="page-section q-pa-md" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col comp-grid" >
                <Title title={$t('issuetrack')}   titleClass="text-lg font-bold text-primary" subTitleClass="text-500"      separator={false} />
            </div>
        </div>
    </div>
</section>
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col-7 comp-grid" >
                <div className="card  s">
                    <img src="images/map1.png"   style={{maxWidth:'100%', width:'150vh', height:'40vh'}} />
                </div>
            </div>
            <div className="col-5 comp-grid" >
                <section className="page-section mb-3" >
                    <div className="container-fluid">
                        <div className="grid ">
                            <div className="col comp-grid" >
                                <div className="card  sp-3 mb-3">
                                    <DataSource onLoad={(options) => setFilterOptions('tasktype', options)}  apiPath="components_data/tasktype_option_list"  >
                                        {
                                        ({ response }) => 
                                        <>
                                        <Title title={$t('filterByTasktype')}  headerClass="p-3" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                                        <div className="">
                                            {
                                            filters.tasktype.options.map((option) => {
                                            return (
                                            <div key={option.value} className="field-radiobutton">
                                                <RadioButton inputId={option.value} name="tasktype" value={option.value} onChange={(e) => setFilterValue('tasktype', e.value)}  checked={filters.tasktype.value === option.value} />
                                                <label htmlFor={option.value}>{option.label}</label>
                                            </div>
                                            )
                                            })
                                            }
                                        </div>
                                        </>
                                        }
                                    </DataSource>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-section mb-3" >
                    <div className="container-fluid">
                        <div className="grid ">
                            <div className="col comp-grid" >
                                <div className="card  sp-0 mb-3">
                                    <DataSource onLoad={(options) => setFilterOptions('description', options)}  apiPath="components_data/description_option_list"  >
                                        {
                                        ({ response }) => 
                                        <>
                                        <Title title={$t('filterByDescription')}  headerClass="p-3" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                                        <ListBox value={filters.description.value} className="w-full" options={filters.description.options} onChange={(e) => setFilterValue('description', e.value)} >
                                            </ListBox >
                                            </>
                                            }
                                        </DataSource>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid ">
                <div className="col-7 comp-grid" >
                    <div className="card  s">
                        <div className="q-pa-md">
                            <div className="font-bold text-lg">AppUse</div>
                            <div className="text-500"></div>
                            <hr />
                            <div className="row q-col-gutter-sm">
                                <div className="col">
                                    <DataSource   apiPath="components_data/barchart_appuse"  >
                                        {
                                        ({ response }) => 
                                        <>
                                        <Chart data={response} type="bar" options={{
                                        scales: {
                                        y: {
                                        title: {
                                        display: true,
                                        text: $t('')
                                        }
                                        },
                                        x: {
                                        title: {
                                        display: true,
                                        text: $t('')
                                        }
                                        }
                                        }
                                        }
                                        }  />
                                        </>
                                        }
                                    </DataSource>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4 comp-grid" >
                    <div className="card  s">
                        <div className="q-pa-md">
                            <div className="font-bold text-lg">AppUse1</div>
                            <div className="text-500"></div>
                            <hr />
                            <div className="row q-col-gutter-sm">
                                <div className="col">
                                    <DataSource   apiPath="components_data/barchart_appuse"  >
                                        {
                                        ({ response }) => 
                                        <>
                                        <Chart data={response} type="pie" options={{
                                        scales: {
                                        y: {
                                        title: {
                                        display: true,
                                        text: $t('')
                                        }
                                        },
                                        x: {
                                        title: {
                                        display: true,
                                        text: $t('')
                                        }
                                        }
                                        }
                                        }
                                        }  />
                                        </>
                                        }
                                    </DataSource>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
		</main>
	);
}
