import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { $t } from 'hooks/i18n';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import { useQuery } from 'react-query';

import useEditPage from 'hooks/useEditPage';
import axios from "axios";

const ProcessserviceEditPage = (props) => {
	const app = useApp();

	// **جلب البيانات باستخدام useQuery**
	const { data: serviceRequests = [], isLoading: loadingServiceRequests } = useQuery(
		'serviceRequests',
		() => axios.get('/servicerequests').then((res) => res.data.records) // استخراج البيانات من الاستجابة
	);

	const { data: teams = [], isLoading: loadingTeams } = useQuery(
		'teams',
		() => axios.get('/teams').then((res) => res.data.records) // استخراج البيانات من الاستجابة
	);

	// **Schema للتحقق من صحة النموذج**
	const validationSchema = yup.object().shape({
		service_request_id: yup.number().required().label($t('serviceRequestId')),
		status: yup.string().required().label($t('status')),
		details: yup.string().nullable().label($t('details')),
		team_id: yup.number().nullable().label($t('teamId')),
		assigned_date: yup.date().nullable().label($t('assignedDate')),
	});

	// **القيم الافتراضية للنموذج**
	const formDefaultValues = {
		service_request_id: '',
		status: '',
		details: '',
		team_id: '',
		assigned_date: '',
	};

	// **منطق الصفحة**
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController;

	// **التعامل بعد الحفظ**
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if (app.isDialogOpen()) {
			app.closeDialogs();
		} else if (props.redirect) {
			app.navigate(`/processservice`);
		}
	}

	// **عرض التحميل**
	if (loading || loadingServiceRequests || loadingTeams) {
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{ width: '50px', height: '50px' }} />
			</div>
		);
	}

	// **عرض الخطأ**
	if (apiRequestError) {
		return <PageRequestError error={apiRequestError} />;
	}

	// **عرض الصفحة**
	if (pageReady) {
		return (
			<main id="ProcessserviceEditPage" className="main-page">
				{props.showHeader && (
					<section className="page-section mb-3">
						<div className="container">
							<div className="grid justify-content-between align-items-center">
								{!props.isSubPage && (
									<div className="col-fixed">
										<Button
											onClick={() => app.navigate(-1)}
											label={$t('')}
											className="p-button p-button-text"
											icon="pi pi-arrow-left"
										/>
									</div>
								)}
								<div className="col">
									<Title
										title={$t('editProcessService')}
										titleClass="text-2xl text-primary font-bold"
										subTitleClass="text-500"
										separator={false}
									/>
								</div>
							</div>
						</div>
					</section>
				)}
				<section className="page-section">
					<div className="container">
						<div className="grid">
							<div className="md:col-9 sm:col-12 comp-grid">
								<Formik
									initialValues={formData}
									validationSchema={validationSchema}
									onSubmit={(values) => submitForm(values)}
								>
									{(formik) => (
										<Form className={`${!props.isSubPage ? 'card' : ''}`}>
											<div className="grid">
												<div className="col-12">
													<div className="formgrid grid">
														<div className="col-12 md:col-3">{$t('serviceRequestId')} *</div>
														<div className="col-12 md:col-9">
															<Dropdown
																name="service_request_id"
																value={formik.values.service_request_id}
																options={serviceRequests.map((sr) => ({
																	label: sr.service_type_name +`  (${sr.id}) `+'رقم الطلب ', // عرض اسم نوع الخدمة
																	value: sr.id,
																}))}
																onChange={(e) =>
																	formik.setFieldValue('service_request_id', e.value)
																}
																placeholder={$t('selectAServiceRequest')}
																className={inputClassName(formik?.errors?.service_request_id)}
															/>
															<ErrorMessage
																name="service_request_id"
																component="span"
																className="p-error"
															/>
														</div>
													</div>
												</div>
												<div className="col-12">
													<div className="formgrid grid">
														<div className="col-12 md:col-3">{$t('status')} *</div>
														<div className="col-12 md:col-9">
															<Dropdown
																name="status"
																value={formik.values.status}
																options={[
																	{ label: 'In Progress', value: 'in_progress' },
																	{ label: 'Finished', value: 'finished' },
																]}
																onChange={(e) => formik.setFieldValue('status', e.value)}
																placeholder={$t('selectStatus')}
																className={inputClassName(formik?.errors?.status)}
															/>
															<ErrorMessage name="status" component="span" className="p-error" />
														</div>
													</div>
												</div>
												<div className="col-12">
													<div className="formgrid grid">
														<div className="col-12 md:col-3">{$t('details')}</div>
														<div className="col-12 md:col-9">
															<InputTextarea
																name="details"
																value={formik.values.details}
																onChange={formik.handleChange}
																placeholder={$t('enterDetails')}
																className={inputClassName(formik?.errors?.details)}
															/>
															<ErrorMessage name="details" component="span" className="p-error" />
														</div>
													</div>
												</div>
												<div className="col-12">
													<div className="formgrid grid">
														<div className="col-12 md:col-3">{$t('teamId')}</div>
														<div className="col-12 md:col-9">
															<Dropdown
																name="team_id"
																value={formik.values.team_id}
																options={teams.map((team) => ({
																	label: team.teamname,
																	value: team.teamid,
																}))}
																onChange={(e) => formik.setFieldValue('team_id', e.value)}
																placeholder={$t('selectATeam')}
																className={inputClassName(formik?.errors?.team_id)}
															/>
															<ErrorMessage name="team_id" component="span" className="p-error" />
														</div>
													</div>
												</div>
												<div className="col-12">
													<div className="formgrid grid">
														<div className="col-12 md:col-3">{$t('assignedDate')}</div>
														<div className="col-12 md:col-9">
															<Calendar
																name="assigned_date"
																value={formik.values.assigned_date}
																onChange={(e) =>
																	formik.setFieldValue('assigned_date', e.value)
																}
																dateFormat="yy-mm-dd"
																showButtonBar
																className={inputClassName(formik?.errors?.assigned_date)}
															/>
															<ErrorMessage
																name="assigned_date"
																component="span"
																className="p-error"
															/>
														</div>
													</div>
												</div>
											</div>
											{props.showFooter && (
												<div className="text-center my-3">
													<Button
														type="submit"
														label={$t('update')}
														icon="pi pi-send"
														className="p-button-primary"
														loading={saving}
													/>
												</div>
											)}
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</section>
			</main>
		);
	}
};

ProcessserviceEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'processservice',
	apiPath: 'processservice/edit',
	routeName: 'processserviceedit',
	submitButtonLabel: $t('update'),
	formValidationError: $t('formIsInvalid'),
	formValidationMsg: $t('pleaseCompleteTheForm'),
	msgTitle: $t('updateRecord'),
	msgAfterSave: $t('recordUpdatedSuccessfully'),
	msgBeforeSave: $t(''),
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false,
};

export default ProcessserviceEditPage;
