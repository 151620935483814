import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import AttachmentsList from 'pages/attachments/List';
import AttachmentsView from 'pages/attachments/View';
import AttachmentsAdd from 'pages/attachments/Add';
import AttachmentsEdit from 'pages/attachments/Edit';
import FeedbackList from 'pages/feedback/List';
import FeedbackView from 'pages/feedback/View';
import FeedbackAdd from 'pages/feedback/Add';
import FeedbackEdit from 'pages/feedback/Edit';
import ProcessreportsList from 'pages/processreports/List';
import ProcessreportsView from 'pages/processreports/View';
import ProcessreportsAdd from 'pages/processreports/Add';
import ProcessreportsEdit from 'pages/processreports/Edit';
import ProcessserviceList from 'pages/processservice/List';
import ProcessserviceView from 'pages/processservice/View';
import ProcessserviceAdd from 'pages/processservice/Add';
import ProcessserviceEdit from 'pages/processservice/Edit';
import ReportsList from 'pages/reports/List';
import ReportsView from 'pages/reports/View';
import ReportsAdd from 'pages/reports/Add';
import ReportsEdit from 'pages/reports/Edit';
import ServicerequestsList from 'pages/servicerequests/List';
import ServicerequestsView from 'pages/servicerequests/View';
import ServicerequestsAdd from 'pages/servicerequests/Add';
import ServicerequestsEdit from 'pages/servicerequests/Edit';
import ServicetypesList from 'pages/servicetypes/List';
import ServicetypesView from 'pages/servicetypes/View';
import ServicetypesAdd from 'pages/servicetypes/Add';
import ServicetypesEdit from 'pages/servicetypes/Edit';
import TeammembersList from 'pages/teammembers/List';
import TeammembersView from 'pages/teammembers/View';
import TeammembersAdd from 'pages/teammembers/Add';
import TeammembersEdit from 'pages/teammembers/Edit';
import TeamsList from 'pages/teams/List';
import TeamsView from 'pages/teams/View';
import TeamsAdd from 'pages/teams/Add';
import TeamsEdit from 'pages/teams/Edit';
import UsersList from 'pages/users/List';
import UsersView from 'pages/users/View';
import UsersAdd from 'pages/users/Add';
import UsersEdit from 'pages/users/Edit';
import DeploymentsPage from 'pages/custom/DeploymentsPage';
import IssuetrackPage from 'pages/custom/IssuetrackPage';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
import ReportsDashboard from "./pages/reports";
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				<Route path="/report" element={<ReportsDashboard />} />
				{/* attachments pages routes */}
				<Route path="/attachments" element={<AttachmentsList />} />
				<Route path="/attachments/:fieldName/:fieldValue" element={<AttachmentsList />} />
				<Route path="/attachments/index/:fieldName/:fieldValue" element={<AttachmentsList />} />
				<Route path="/attachments/view/:pageid" element={<AttachmentsView />} />
				<Route path="/attachments/add" element={<AttachmentsAdd />} />
				<Route path="/attachments/edit/:pageid" element={<AttachmentsEdit />} />

				{/* feedback pages routes */}
				<Route path="/feedback" element={<FeedbackList />} />
				<Route path="/feedback/:fieldName/:fieldValue" element={<FeedbackList />} />
				<Route path="/feedback/index/:fieldName/:fieldValue" element={<FeedbackList />} />
				<Route path="/feedback/view/:pageid" element={<FeedbackView />} />
				<Route path="/feedback/add" element={<FeedbackAdd />} />
				<Route path="/feedback/edit/:pageid" element={<FeedbackEdit />} />

				{/* processreports pages routes */}
				<Route path="/processreports" element={<ProcessreportsList />} />
				<Route path="/processreports/:fieldName/:fieldValue" element={<ProcessreportsList />} />
				<Route path="/processreports/index/:fieldName/:fieldValue" element={<ProcessreportsList />} />
				<Route path="/processreports/view/:pageid" element={<ProcessreportsView />} />
				<Route path="/processreports/add" element={<ProcessreportsAdd />} />
				<Route path="/processreports/edit/:pageid" element={<ProcessreportsEdit />} />

				{/* processservice pages routes */}
				<Route path="/processservice" element={<ProcessserviceList />} />
				<Route path="/processservice/:fieldName/:fieldValue" element={<ProcessserviceList />} />
				<Route path="/processservice/index/:fieldName/:fieldValue" element={<ProcessserviceList />} />
				<Route path="/processservice/view/:pageid" element={<ProcessserviceView />} />
				<Route path="/processservice/add" element={<ProcessserviceAdd />} />
				<Route path="/processservice/edit/:pageid" element={<ProcessserviceEdit />} />

				{/* reports pages routes */}
				<Route path="/reports" element={<ReportsList />} />
				<Route path="/reports/:fieldName/:fieldValue" element={<ReportsList />} />
				<Route path="/reports/index/:fieldName/:fieldValue" element={<ReportsList />} />
				<Route path="/reports/view/:pageid" element={<ReportsView />} />
				<Route path="/reports/add" element={<ReportsAdd />} />
				<Route path="/reports/edit/:pageid" element={<ReportsEdit />} />

				{/* servicerequests pages routes */}
				<Route path="/servicerequests" element={<ServicerequestsList />} />
				<Route path="/servicerequests/:fieldName/:fieldValue" element={<ServicerequestsList />} />
				<Route path="/servicerequests/index/:fieldName/:fieldValue" element={<ServicerequestsList />} />
				<Route path="/servicerequests/view/:pageid" element={<ServicerequestsView />} />
				<Route path="/servicerequests/add" element={<ServicerequestsAdd />} />
				<Route path="/servicerequests/edit/:pageid" element={<ServicerequestsEdit />} />

				{/* servicetypes pages routes */}
				<Route path="/servicetypes" element={<ServicetypesList />} />
				<Route path="/servicetypes/:fieldName/:fieldValue" element={<ServicetypesList />} />
				<Route path="/servicetypes/index/:fieldName/:fieldValue" element={<ServicetypesList />} />
				<Route path="/servicetypes/view/:pageid" element={<ServicetypesView />} />
				<Route path="/servicetypes/add" element={<ServicetypesAdd />} />
				<Route path="/servicetypes/edit/:pageid" element={<ServicetypesEdit />} />

				{/* teammembers pages routes */}
				<Route path="/teammembers" element={<TeammembersList />} />
				<Route path="/teammembers/:fieldName/:fieldValue" element={<TeammembersList />} />
				<Route path="/teammembers/index/:fieldName/:fieldValue" element={<TeammembersList />} />
				<Route path="/teammembers/view/:pageid" element={<TeammembersView />} />
				<Route path="/teammembers/add" element={<TeammembersAdd />} />
				<Route path="/teammembers/edit/:pageid" element={<TeammembersEdit />} />

				{/* teams pages routes */}
				<Route path="/teams" element={<TeamsList />} />
				<Route path="/teams/:fieldName/:fieldValue" element={<TeamsList />} />
				<Route path="/teams/index/:fieldName/:fieldValue" element={<TeamsList />} />
				<Route path="/teams/view/:pageid" element={<TeamsView />} />
				<Route path="/teams/add" element={<TeamsAdd />} />
				<Route path="/teams/edit/:pageid" element={<TeamsEdit />} />

				{/* users pages routes */}
				<Route path="/users" element={<UsersList />} />
				<Route path="/users/:fieldName/:fieldValue" element={<UsersList />} />
				<Route path="/users/index/:fieldName/:fieldValue" element={<UsersList />} />
				<Route path="/users/view/:pageid" element={<UsersView />} />
				<Route path="/users/add" element={<UsersAdd />} />
				<Route path="/users/edit/:pageid" element={<UsersEdit />} />
				<Route path="/deployments" element={<DeploymentsPage />} />
				<Route path="/issuetrack" element={<IssuetrackPage />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
