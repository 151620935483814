import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { $t } from 'hooks/i18n';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
import { useQuery } from 'react-query';
import axios from 'axios';

const TeammembersAddPage = (props) => {
	const app = useApp();

	// جلب المستخدمين الذين لديهم UserType = "TeamMember"
	const { data: users = [], isLoading: loadingUsers } = useQuery('users', async () => {
		const response = await axios.get('/users/index/UserType/TeamMember');
		return response.data.records.map((user) => ({
			label: user.fullname,
			value: user.userid,
		}));
	});

	// جلب قائمة الفرق
	const { data: teams = [], isLoading: loadingTeams } = useQuery('teams', async () => {
		const response = await axios.get('/teams');
		return response.data.records.map((team) => ({
			label: team.teamname,
			value: team.teamid,
		}));
	});

	// قواعد التحقق
	const validationSchema = yup.object().shape({
		userid: yup.number().required().label($t('userid')),
		teamid: yup.number().required().label($t('teamid')),
	});

	// القيم الافتراضية للنموذج
	const formDefaultValues = {
		userid: '',
		teamid: '',
	};

	// منطق الصفحة
	const pageController = useAddPage({ props, formDefaultValues, afterSubmit });

	// استخراج البيانات من منطق الصفحة
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;

	// الإجراء بعد الإرسال
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if (app.isDialogOpen()) {
			app.closeDialogs(); // إغلاق الحوار إذا كان مفتوحًا
		} else if (props.redirect) {
			app.navigate(`/teammembers`);
		}
	}

	// تحميل البيانات
	if (loading || loadingUsers || loadingTeams) {
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{ width: '50px', height: '50px' }} />
			</div>
		);
	}

	// الصفحة جاهزة للعرض
	if (pageReady) {
		return (
			<main id="TeammembersAddPage" className="main-page">
				{props.showHeader && (
					<section className="page-section mb-3">
						<div className="container">
							<div className="grid justify-content-between align-items-center">
								{!props.isSubPage && (
									<div className="col-fixed">
										<Button
											onClick={() => app.navigate(-1)}
											label={$t('')}
											className="p-button p-button-text"
											icon="pi pi-arrow-left"
										/>
									</div>
								)}
								<div className="col">
									<Title
										title={$t('addNewTeammember')}
										titleClass="text-2xl text-primary font-bold"
										subTitleClass="text-500"
										separator={false}
									/>
								</div>
							</div>
						</div>
					</section>
				)}
				<section className="page-section">
					<div className="container">
						<div className="grid">
							<div className="md:col-9 sm:col-12 comp-grid">
								<div>
									<Formik
										initialValues={formData}
										validationSchema={validationSchema}
										onSubmit={(values, actions) => submitForm(values)}
									>
										{(formik) => (
											<>
												<Form className={`${!props.isSubPage ? 'card' : ''}`}>
													<div className="grid">
														{/* Dropdown لاختيار المستخدم */}
														<div className="col-12">
															<div className="formgrid grid">
																<div className="col-12 md:col-3">{$t('userid')} *</div>
																<div className="col-12 md:col-9">
																	<Dropdown
																		name="userid"
																		value={formik.values.userid}
																		options={users}
																		onChange={(e) =>
																			formik.setFieldValue('userid', e.value)
																		}
																		placeholder={$t('selectUser')}
																		className={inputClassName(formik?.errors?.userid)}
																	/>
																	<ErrorMessage name="userid" component="span" className="p-error" />
																</div>
															</div>
														</div>

														{/* Dropdown لاختيار الفريق */}
														<div className="col-12">
															<div className="formgrid grid">
																<div className="col-12 md:col-3">{$t('teamid')} *</div>
																<div className="col-12 md:col-9">
																	<Dropdown
																		name="teamid"
																		value={formik.values.teamid}
																		options={teams}
																		onChange={(e) =>
																			formik.setFieldValue('teamid', e.value)
																		}
																		placeholder={$t('selectTeam')}
																		className={inputClassName(formik?.errors?.teamid)}
																	/>
																	<ErrorMessage name="teamid" component="span" className="p-error" />
																</div>
															</div>
														</div>
													</div>

													{/* زر الإرسال */}
													{props.showFooter && (
														<div className="text-center my-3">
															<Button
																onClick={(e) => handleSubmit(e, formik)}
																className="p-button-primary"
																type="submit"
																label={$t('submit')}
																icon="pi pi-send"
																loading={saving}
															/>
														</div>
													)}
												</Form>
											</>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		);
	}
};

// الخصائص الافتراضية
TeammembersAddPage.defaultProps = {
	primaryKey: 'memberid',
	pageName: 'teammembers',
	apiPath: 'teammembers/add',
	routeName: 'teammembersadd',
	submitButtonLabel: $t('submit'),
	formValidationError: $t('formIsInvalid'),
	formValidationMsg: $t('pleaseCompleteTheForm'),
	msgTitle: $t('createRecord'),
	msgAfterSave: $t('recordAddedSuccessfully'),
	msgBeforeSave: $t(''),
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false,
};

export default TeammembersAddPage;
