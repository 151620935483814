import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { $t } from 'hooks/i18n';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const ServicerequestsEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		user_id: yup.string().required().label($t('userId')),
		service_id: yup.string().required().label($t('serviceId')),
		lat: yup.string().required().label($t('lat')),
		lan: yup.string().required().label($t('lan')),
		count: yup.number().required().label($t('count')),
		cost: yup.number().required().label($t('cost')),
		status: yup.string().required().label($t('status'))
	});
	// form default values
	const formDefaultValues = {
		user_id: '', 
		service_id: '', 
		lat: '', 
		lan: '', 
		count: '', 
		cost: '', 
		status: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/servicerequests`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="ServicerequestsEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label={$t('')}  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title={$t('editServiceRequest')}   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('userId')} *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/userid_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="user_id"     optionLabel="label" optionValue="value" value={formik.values.user_id} onChange={formik.handleChange} options={response} label={$t('userId')}  placeholder={$t('selectAValue')}  className={inputClassName(formik?.errors?.user_id)}   />
                                                    <ErrorMessage name="user_id" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('serviceId')} *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/service_id_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="service_id"     optionLabel="label" optionValue="value" value={formik.values.service_id} onChange={formik.handleChange} options={response} label={$t('serviceId')}  placeholder={$t('selectAValue')}  className={inputClassName(formik?.errors?.service_id)}   />
                                                    <ErrorMessage name="service_id" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('lat')} *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="lat"  onChange={formik.handleChange}  value={formik.values.lat}   label={$t('lat')} type="text" placeholder={$t('enterLat')}        className={inputClassName(formik?.errors?.lat)} />
                                                <ErrorMessage name="lat" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('lan')} *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="lan"  onChange={formik.handleChange}  value={formik.values.lan}   label={$t('lan')} type="text" placeholder={$t('enterLan')}        className={inputClassName(formik?.errors?.lan)} />
                                                <ErrorMessage name="lan" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('count')} *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="count"  onChange={formik.handleChange}  value={formik.values.count}   label={$t('count')} type="number" placeholder={$t('enterCount')}  min={0}  step="any"    className={inputClassName(formik?.errors?.count)} />
                                                <ErrorMessage name="count" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('cost')} *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="cost"  onChange={formik.handleChange}  value={formik.values.cost}   label={$t('cost')} type="number" placeholder={$t('enterCost')}  min={0}  step={0.1}    className={inputClassName(formik?.errors?.cost)} />
                                                <ErrorMessage name="cost" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                {$t('status')} *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="status"     optionLabel="label" optionValue="value" value={formik.values.status} onChange={formik.handleChange} options={app.menus.status2} label={$t('status')}  placeholder={$t('selectAValue')}  className={inputClassName(formik?.errors?.status)}   />
                                                <ErrorMessage name="status" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label={$t('update')} icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
ServicerequestsEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'servicerequests',
	apiPath: 'servicerequests/edit',
	routeName: 'servicerequestsedit',
	submitButtonLabel: $t('update'),
	formValidationError: $t('formIsInvalid'),
	formValidationMsg: $t('pleaseCompleteTheForm'),
	msgTitle: $t('updateRecord'),
	msgAfterSave: $t('recordUpdatedSuccessfully'),
	msgBeforeSave: $t(''),
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ServicerequestsEditPage;
