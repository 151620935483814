import { useState } from 'react';
import { $t } from 'hooks/i18n';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

export default function DeploymentsPage() {
		const app = useApp();
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="DeploymentsPage" className="main-page">
<section className="page-section q-pa-md" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col comp-grid" >
                <Title title={$t('deployments')}   titleClass="text-lg font-bold text-primary" subTitleClass="text-500"      separator={false} />
            </div>
        </div>
    </div>
</section>
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid justify-content-center">
            <div className="col-8 comp-grid" >
                <div className="card  s">
                    <img src="images/map1.png"   style={{maxWidth:'100%', height:'400px'}} />
                </div>
            </div>
            <div className="col 4  comp-grid" >
                <section className="page-section mb-3" >
                    <div className="container-fluid">
                        <div className="grid ">
                            <div className="col-6 comp-grid" >
                                <Link to={`/areas/add`}>
                                    <Button label={$t('completedTasks')}  type="button" className="p-button w-full bg-green-500 "  />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="page-section mb-3" >
                        <div className="container-fluid">
                            <div className="grid ">
                                <div className="col-6 comp-grid" >
                                    <Link to={`/areas/add`}>
                                        <Button label={$t('incompleteTasks')}  type="button" className="p-button w-full bg-orange-500 "  />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="page-section mb-3" >
                            <div className="container-fluid">
                                <div className="grid ">
                                    <div className="col-6 comp-grid" >
                                        <Link to={`/areas/add`}>
                                            <Button label={$t('pendingTasks')}  type="button" className="p-button w-full bg-red-500 "  />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="page-section mb-3" >
                                <div className="container-fluid">
                                    <div className="grid ">
                                        <div className="sm:col-12 comp-grid" >
                                            <div className="card  sp-3 mb-3">
                                                <Title title={$t('messages')}  headerClass="p-3" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                                                <div>
                                                    <Menu className="w-full" model={app.menus.deploymentsDeploymentsMenu} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
		</main>
	);
}
