import React from "react";
import { useQuery } from "react-query";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";

export default function StatisticsPage() {
    const { data: statsData, isLoading } = useQuery("statistics", () =>
        fetch("/statistics").then((res) => res.json())
    );

    if (isLoading) {
        return (
            <div className="flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <ProgressSpinner />
            </div>
        );
    }

    const stats = statsData || {};
    const reportsStats = stats.data.reports || {};
    const serviceStats = stats.data.ServiceRequests || {};
    const usersStats = stats.data.users || {};
    const teamsStats = stats.data.teams || {};

    // إعداد بيانات الرسم البياني
    const chartData = {
        labels: ["معلقة", "قيد التنفيذ", "منتهية"],
        datasets: [
            {
                label: "حالة البلاغات",
                data: [reportsStats.pending, reportsStats.in_progress, reportsStats.finished],
                backgroundColor: ["#FFA500", "#0000FF", "#008000"],
            },
            {
                label: "طلبات الخدمة",
                data: [serviceStats.pending, serviceStats.finished],
                backgroundColor: ["#FFA500", "#008000"],
            },
        ],
    };

    const chartOptions = {
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    return (
        <div className="container my-5" dir="rtl">
            <h1 className="text-center mb-4">إحصائيات النظام</h1>

            <div className="grid">
                {/* قسم البلاغات */}
                <div className="col-12 md:col-4">
                    <Card title="البلاغات">
                        <p>إجمالي البلاغات: {reportsStats.total}</p>
                        <p>معلقة: {reportsStats.pending}</p>
                        <p>قيد التنفيذ: {reportsStats.in_progress}</p>
                        <p>منتهية: {reportsStats.finished}</p>
                    </Card>
                </div>

                {/* قسم طلبات الخدمة */}
                <div className="col-12 md:col-4">
                    <Card title="طلبات الخدمة">
                        <p>إجمالي الطلبات: {serviceStats.total}</p>
                        <p>إجمالي التكلفة: {serviceStats.total_cost} وحدة</p>
                        <p>معلقة: {serviceStats.pending}</p>
                        <p>منتهية: {serviceStats.finished}</p>
                    </Card>
                </div>

                {/* قسم المستخدمين */}
                <div className="col-12 md:col-4">
                    <Card title="المستخدمين">
                        <p>إجمالي المستخدمين: {usersStats.total}</p>
                    </Card>
                </div>

                {/* قسم الفرق */}
                <div className="col-12 md:col-4">
                    <Card title="الفرق">
                        <p>إجمالي الفرق: {teamsStats.total}</p>
                    </Card>
                </div>
            </div>

            {/* قسم الرسوم البيانية */}
            <div className="my-5">
                <h2 className="text-center">نظرة عامة على الإحصائيات</h2>
                <Chart type="bar" data={chartData} options={chartOptions} />
            </div>
        </div>
    );
}
