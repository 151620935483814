import { $t } from 'hooks/i18n';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useListPage from 'hooks/useListPage';

const ProcessserviceListPage = (props) => {
	const app = useApp();
	const filterSchema = {
		search: {
			tagTitle: $t('search'),
			value: '',
			valueType: 'single',
			options: [],
		},
	};

	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } = pagination;

	function ActionButton(data) {
		const items = [
			{
				label: $t('view'),
				command: () => { app.navigate(`/processservice/view/${data.id}`); },
				icon: "pi pi-eye",
			},
			{
				label: $t('edit'),
				command: () => { app.navigate(`/processservice/edit/${data.id}`); },
				icon: "pi pi-pencil",
			},
			{
				label: $t('delete'),
				command: () => { deleteItem(data.id); },
				icon: "pi pi-trash",
			},
		];
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}

	function ServiceRequestTitleTemplate(data) {
		return data?.service_type_name || '-';
	}

	function TeamNameTemplate(data) {
		return data?.team_name || '-';
	}

	function PageLoading() {
		if (loading) {
			return (
				<div className="flex align-items-center justify-content-center text-gray-500 p-3">
					<div><ProgressSpinner style={{ width: '30px', height: '30px' }} /></div>
					<div className="font-bold text-lg">{$t('loading')}</div>
				</div>
			);
		}
	}

	function EmptyRecordMessage() {
		if (pageReady && !records.length) {
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					{$t('noRecordFound')}
				</div>
			);
		}
	}

	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title={$t('deleteSelected')} />
				</div>
			);
		}
	}

	if (apiRequestError) {
		return <PageRequestError error={apiRequestError} />;
	}

	return (
		<main id="ProcessserviceListPage" className="main-page">
			{props.showHeader && (
				<section className="page-section mb-3">
					<div className="container-fluid">
						<div className="grid justify-content-between align-items-center">
							<div className="col">
								<Title title={$t('processService')} titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
							</div>
							<div className="col-fixed">
								<Link to={`/processservice/add`}>
									<Button label={$t('addNewProcessService')} icon="pi pi-plus" type="button" className="p-button w-full bg-primary" />
								</Link>
							</div>
							<div className="col-12 md:col-3">
                                <span className="p-input-icon-left w-full">
                                    <i className="pi pi-search" />
                                    <InputText placeholder={$t('search')} className="w-full" value={filters.search.value} onChange={(e) => setFilterValue('search', e.target.value)} />
                                </span>
							</div>
						</div>
					</div>
				</section>
			)}
			<section className="page-section">
				<div className="container-fluid">
					<div className="grid">
						<div className="col comp-grid">
							<FilterTags filterController={filterController} />
							<div>
								<div className="page-records">
									<DataTable
										lazy={true}
										loading={loading}
										selectionMode="checkbox"
										selection={selectedItems}
										onSelectionChange={(e) => setSelectedItems(e.value)}
										value={records}
										dataKey="id"
										sortField={sortBy}
										sortOrder={sortOrder}
										onSort={onSort}
										className="p-datatable-sm"
										stripedRows={true}
										showGridlines={false}
										rowHover={true}
										responsiveLayout="stack"
										emptyMessage={<EmptyRecordMessage />}
									>
										<Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column>
										<Column field="service_request_title" header={$t('serviceRequestType')} body={ServiceRequestTitleTemplate}></Column>
										<Column field="status" header={$t('status')}></Column>
										<Column field="details" header={$t('details')}></Column>
										<Column field="team_name" header={$t('teamName')} body={TeamNameTemplate}></Column>
										<Column field="updated_at" header={$t('updatedAt')}></Column>
										<Column field="assigned_date" header={$t('assignedDate')}></Column>
										<Column headerStyle={{ width: '2rem' }} headerClass="text-center" body={ActionButton}></Column>
									</DataTable>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

ProcessserviceListPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'processservice',
	apiPath: 'processservice/index',
	routeName: 'processservicelist',
	msgBeforeDelete: $t('promptDeleteRecord'),
	msgTitle: $t('deleteRecord'),
	msgAfterDelete: $t('recordDeletedSuccessfully'),
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
};

export default ProcessserviceListPage;
